<script setup>
    import { ref, onMounted, computed, onUnmounted } from "vue";
    import Editor from 'primevue/editor';
    import FileDropBox from "../../components/FileDropBox.vue";
    import axios from "axios";
    import Quill from "quill";
    import AutoComplete from 'primevue/autocomplete';
    import {decodeVin} from '../../services/vin-decoder';
    import { getVehicle} from '../../services/vehicle_apis';
    import { VueDraggableNext } from "vue-draggable-next";
    import { useFileDropBoxStore } from "../../stores/filedropbox";
    import { useSystemStore} from "../../stores/system"
    import { useAuthStore } from "../../stores/auth";
    import TransactionTable from "../../components/CreateVehicle/TransactionTable.vue";
    import router from "../../router";
    import {useRoute} from 'vue-router'
    import { useDealerStore } from "../../stores/dealer";

    const route = useRoute();

    defineProps({
        id: String
    })
    const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;

    const transactions = ref([])
    const authStore = useAuthStore();
    const fileDropBoxStore = useFileDropBoxStore();
    const uniqueVehicleId = ref(generateUUID());
    fileDropBoxStore.setVehicleId(uniqueVehicleId.value);
    const editorKey = ref(0);
    const errors = ref({
        add_vehicle: {
            status : false,
            message : ""
        },
        decoder: {
            status: false,
            message: "VIN Decoder: Error occured."
        },
        stock_number: {
            status: false,
            message: "Stock number is required"
        },
        instock_date: {
            status: false,
            message: "Instock date is required"
        },
        vehicle: {
            vin: {
                status: false,
                message: ""
            },
            year: {
                status: false,
                message: ""
            },
            make: {
                status: false,
                message: "Vehicle make is required"
            },
            model: {
                status: false,
                message: ""
            },
            trim: {
                status: false,
                message: "Trim is required"
            },
            transmission: {
                status: false,
                message: ""
            },
            style: {
                status: false,
                message: ""
            },
            exterior_color: {
                status: false,
                message: "Exterior colour is required"
            },
            interior_color: {
                status: false,
                message: "Interior colour is required"
            },
            odometer: {
                status: false,
                message: "Odometer reading is required"
            },
            cylinders: {
                status: false,
                message: ""
            },
            engine_size: {
                status: false,
                message: ""
            }
        }
    })
    onUnmounted(()=> {
        fileDropBoxStore.uploadedFiles = []
    })

    async function LoadVehicleData() {
        const vehicle_dataa = await getVehicle(route.params.id);
        if (vehicle_dataa?.status === 200) {
            console.log(vehicle_dataa)
            vehicle.value.vin = vehicle_dataa.data.data.vin;
            vehicle.value.make = vehicle_dataa.data.data.make;
            vehicle.value.model = vehicle_dataa.data.data.model;
            vehicle.value.year = vehicle_dataa.data.data.year;
            vehicle.value.stock_number = vehicle_dataa.data.data.stock_number;
            vehicle.value.in_stock_date = vehicle_dataa.data.data.instock_date;
            vehicle.value.status = vehicle_dataa.data.data.status;
            vehicle.value.specifications.trim = vehicle_dataa.data.data.vehicle_specifications.trim;
            vehicle.value.specifications.style = vehicle_dataa.data.data.vehicle_specifications.style;
            vehicle.value.specifications.engine = vehicle_dataa.data.data.vehicle_specifications.engine;
            vehicle.value.specifications.transmission = vehicle_dataa.data.data.vehicle_specifications.transmission;
            vehicle.value.specifications.body_type = vehicle_dataa.data.data.vehicle_specifications.body_type;
            vehicle.value.vehicle_data.exterior_color = vehicle_dataa.data.data.vehicle_settings.exterior_color;
            vehicle.value.specifications.colors.exterior = vehicle_dataa.data.data.vehicle_specifications.colors.exterior;
            vehicle.value.vehicle_data.interior_color = vehicle_dataa.data.data.vehicle_settings.interior_color;
            vehicle.value.specifications.colors.interior = vehicle_dataa.data.data.vehicle_specifications.colors.interior;
            vehicle.value.odometer = vehicle_dataa.data.data.mileage;
            vehicle.value.vehicle_data.odometer_unit = vehicle_dataa.data.data.vehicle_settings.odometer_unit;
            vehicle.value.vehicle_data.mto_brand = vehicle_dataa.data.data.vehicle_settings.mto_brand;
            vehicle.value.vehicle_data.daily_rental = vehicle_dataa.data.data.vehicle_settings.daily_rental;
            vehicle.value.vehicle_data.certified = vehicle_dataa.data.data.vehicle_settings.certified;
            vehicle.value.vehicle_data.regular_price = vehicle_dataa.data.data.vehicle_settings.regular_price;
            vehicle.value.vehicle_data.sale_price = vehicle_dataa.data.data.vehicle_settings.sale_price;
            vehicle.value.vehicle_data.carfax_report_link = vehicle_dataa.data.data.vehicle_settings.carfax_report_link;
            fileDropBoxStore.uploadedFiles = vehicle_dataa.data.data.vehicle_images;
            uniqueVehicleId.value = vehicle_dataa.data.data.vehicle_settings.unique_vehicle_id;
            fileDropBoxStore.setVehicleId(vehicle_dataa.data.data.vehicle_settings.unique_vehicle_id);
            vehicle.value.specifications.braking = vehicle_dataa.data.data.vehicle_specifications.braking;
            vehicle.value.specifications.colors = vehicle_dataa.data.data.vehicle_specifications.colors;
            vehicle.value.specifications.dimensions = vehicle_dataa.data.data.vehicle_specifications.dimensions;
            vehicle.value.specifications.drivetrain = vehicle_dataa.data.data.vehicle_specifications.drivetrain;
            vehicle.value.specifications.fuel = vehicle_dataa.data.data.vehicle_specifications.fuel;
            vehicle.value.specifications.weight = vehicle_dataa.data.data.vehicle_specifications.weight;

            vehicle.value.features.mechanical_and_powertrain = vehicle_dataa.data.data.vehicle_features.mechanical_and_powertrain;
            vehicle.value.features.exterior = vehicle_dataa.data.data.vehicle_features.exterior;
            vehicle.value.features.interior = vehicle_dataa.data.data.vehicle_features.interior;
            vehicle.value.features.safety = vehicle_dataa.data.data.vehicle_features.safety;

            pw.value = vehicle_dataa.data.data.vehicle_description.html;
            editorKey.value += 1;
            console.log(vehicle.value)
            sortImages();
        }

    }
    onMounted(()=> {
        useSystemStore().enableLoadingStatus();

        LoadVehicleData();

        axios.get('https://auto.dev/api/models?apikey=ZrQEPSkKeW91c2lmQGxldmVsdXBzb2x1dGlvbnMuY28=')
        .then((response) => {
            if (response.status === 200) {
                makes.value = Object.keys(response.data)
                useSystemStore().disableLoadingStatus();

            } else {
                useSystemStore().disableLoadingStatus();
            }
        })
        .catch((error) => {
            useSystemStore().disableLoadingStatus();
        })
    })
    async function processVinDecode() {
        useSystemStore().enableLoadingStatus();
        errors.value.add_vehicle.status = false;

        if (vehicle.value.vin.length === 17) {
            const vehicle_data = await decodeVin(vehicle.value.vin);
            if (vehicle_data.status === 200) {
                vehicle.value.vin = vehicle_data.data.vin;
                vehicle.value.year = vehicle_data.data.year;
                vehicle.value.make = vehicle_data.data.make;
                vehicle.value.model = vehicle_data.data.model;
                vehicle.value.specifications.doors = vehicle_data.data.doors;
                vehicle.value.features.mechanical_and_powertrain = vehicle_data.data.features.mechanical_and_powertrain;
                vehicle.value.features.safety = vehicle_data.data.features.safety;
                vehicle.value.features.interior = vehicle_data.data.features.interior;
                vehicle.value.features.exterior = vehicle_data.data.features.exterior;
                vehicle.value.specifications = vehicle_data.data.specifications;
                vehicle.value.specifications.engine.cylinder = extractNumber(vehicle_data.data.specifications.engine.cylinders);
                vehicle.value.specifications.trim = vehicle_data.data.trim;
                vehicle.value.specifications.style = vehicle_data.data.style;
                vehicle.value.specifications.body_type = vehicle_data.data.body_type;


                useSystemStore().disableLoadingStatus();
            } else {
                useSystemStore().disableLoadingStatus();
                errors.value.add_vehicle.status = true;
                errors.value.add_vehicle.message = 'VIN Decoder is experiencing an issue. The issue has been reported to us and we will fix it shortly.';

            } 
        } else {
            useSystemStore().disableLoadingStatus();
                errors.value.add_vehicle.status = true;
                errors.value.add_vehicle.message = 'Incorrect VIN. Please validate your VIN.';
        }

        
    }

    function extractNumber(str) {
        const match = str.match(/\d+/); // \d+ looks for one or more digits in the string
        return match ? parseInt(match[0], 10) : null; // If a match is found, convert it to an integer, else return null
    }

    function removeFeature(type, key) {
        if (vehicle.value.features[type]) {
            const featureIndex = key;
            if (featureIndex !== -1) {
                vehicle.value.features[type].splice(featureIndex, 1);
            }
        }


    }
    function addFeature(type, event) {
        if (vehicle.value.features[type]) {
            // Ensure the feature doesn't already exist before adding it
            if (!vehicle.value.features[type].includes(event.target.value.trim())) {
                vehicle.value.features[type].push(event.target.value.trim());
                event.target.value = '';
            } else {
                event.target.value = '';
            }
        }
    }

    //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
    const editor =ref("");
    const editor_ref = ref(null);
    const deal = ref({
        deal_number: 1001,
        vehicle_price: 19999.00,
        admin_fee: 499,
        addon_1: 2,
        addon_2: 0,
        addon_3: 0,
        addon_4: 0,
        addon_5: 0,
        extended_warranty: "",
        gap_insurance: "",
        licensing_fee: "",
        gasoline: "",
        trade_in: 1200,
        lien_payout: 7000
    })

    const makes = ref(["Audi", "BMW", "Mercedes Benz", "Toyota", "Honda"]);
    const exteriorColors = ref([]);
    const subtotal = computed(() => {
        return (parseFloat(deal.value.vehicle_price) + parseFloat(deal.value.admin_fee) + parseFloat(deal.value.addon_1) + parseFloat(deal.value.addon_2) + parseFloat(deal.value.addon_3) + parseFloat(deal.value.addon_4) + parseFloat(deal.value.addon_5)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    })
    const vehicle = ref({
        stock_number: "",
        in_stock_date : "",
        status : "in_stock",
        vin: "",
        year: "",
        make: "",
        model: "",
        odometer: 0,
        vehicle_data: {
            odometer_unit: "KM",
            mto_brand: "None",
            daily_rental: false,
            certified: false,
            exterior_color: null,
            interior_color: null,
            regular_price: 0,
            sale_price: 0,
            garage_register: {
                name: "",
                address: "",
                purpose: "",
                plate_no: "",
            }
        },
        specifications: {
            trim: "",
            style: "",
            engine: {
                cylinders: "",
                size: "",
                horsepower: "",
                torque: ""
            },
            transmission: {
                type: "",
                style: ""
            },
            dimensions: {

            },
            fuel: {

            },
            braking: {

            },
            weight: {

            },
            colors: {

            }
        },
        features: 
            {
                mechanical_and_powertrain: [
                ],
                safety: [
                ],
                interior: [
                ],
                exterior: [
                ],

            }
        
    })


    const scanVinButton = computed(() => {
        if (vehicle.value.vin.length > 15) {
            return false;
        } else {
            return true;
        }
    })
const generateAIButton = computed(() => {
    if (vehicle.value.year != "" && vehicle.value.make != "" && vehicle.value.model != "" && vehicle.value.vin != "") {
        return false;
    } else {
        return true;
    }
})

const buttonsStatus = ref({
        decode_vin: true,
        generate_ai: true
    })
    function decodeVin2() {
        
        axios.get('https://auto.dev/api/vin/' + vehicle.value.vin + '?apikey=ZrQEPSkKeW91c2lmQGxldmVsdXBzb2x1dGlvbnMuY28=')
        .then((response) => {
            console.log(response.data)
            if (response.status === 200) {
                vehicle.value.make = response.data.make.name;
                vehicle.value.model = response.data.model.name;
                vehicle.value.year = response.data.years[0].year;
                vehicle.value.engine.cylinder = response.data.engine.cylinder;
                vehicle.value.engine.configuration = response.data.engine.configuration;
                vehicle.value.engine.horsepower = response.data.engine.horsepower;
                vehicle.value.engine.torque = response.data.engine.torque;
                vehicle.value.engine.fuel_type = response.data.engine.type;
                vehicle.value.engine.size = response.data.engine.size;
                vehicle.value.style = response.data.categories.vehicleStyle;

            }
        })
    }

    function generateDescription() {
        useSystemStore().enableLoadingStatus();

        const token = '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';
        const configs = {
            headers: { Authorization: `Bearer ${token}` }
        };

        const body = {
            "model" : "gpt-4o-mini",
            "messages" : [
                {
                    "role" : "system",
                    "content" : "Act as the dealership, generate an inviting and welcoming vehicle description starting with stating that this vehicle just arrived and it is in a great condition (html, do not include DOCTYPE or return html at the start, only use p tags) for " + vehicle.value.year + " " + vehicle.value.make + " " + vehicle.value.model + ". Make sure to only include standard features, fuel economy numbers should be in l/km, the description is used for website and car listing sites."
                }
            ]
        }

        axios.post('https://api.openai.com/v1/chat/completions', body, configs)
        .then ((response) => {
            console.log(response.data)

            pw.value = response.data.choices[0].message.content;
            // editor_instance.value.setContents(editor_instance.value.clipboard.convert({
            //     html: response.data.choices[0].message.content
            // }))

            // editor.value = response.data.choices[0].message.content;
             editorKey.value += 1; // Force the editor to re-render
            useSystemStore().disableLoadingStatus();

        })
    }
    //const editorref = ref();
    const pw = ref('');
    const onEditorLoad = ({instance}) => {
        // console.log(instance)
        
        if (pw.value != '') {
            instance.setContents(instance.clipboard.convert({
                html: pw.value
            }))
            editor_instance.value = instance;

        }


        // instance.setContents(instance.clipboard.convert({
        //     html: editor.value
        // }))
    // console.log(editor.value.textValue);
    // description.value.htmlValue = editor.value.htmlValue;
    // description.value.textValue = editor.value.textValue;



}
const editor_instance = ref(null);
const description = ref({
    htmlValue: '',
    textValue: ''
})
const handleTextChange = (event) => {
    editor_instance.value = event.instance;

    // description.value.htmlValue = event.htmlValue;
    // description.value.textValue = event.textValue;
    };

const filteredMakes = ref([])

function search(e) {
    filteredMakes.value = makes.value.filter((make) => {
    return make.toLowerCase().startsWith(e.query.toLowerCase())
  })
}

function generateUUID() {
    // Create a version 4 UUID
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
const data = ref({});

function submitVehicle() {
    useSystemStore().enableLoadingStatus();
    if (validateFields()) {

        const configs = {
            headers: {
                "x-auth-token" : authStore.getAuthToken()
            }
        }
        data.value.vehicle = vehicle.value;
        data.value.vehicle.vehicle_data.unique_vehicle_id = uniqueVehicleId.value;
        if (editor_instance.value != null) {
            data.value.description = {
                html: editor_instance.value.getSemanticHTML(),
                text: editor_instance.value.getText()
            }
        } else {
            data.value.description = {
                html: '',
                text: ''
            }
        }
        data.value.transactions = transactions.value;
        data.value.images = fileDropBoxStore.uploadedFiles;
        //axios.post('https://enmehned95939.x.pipedream.net/', data.value)
        axios.post(apiEndpoint + '/' + useDealerStore().dealerData.id +'/vehicles/' + route.params.id, data.value, configs)
        .then((response) => {
            if (response.status == 200) {
                useSystemStore().disableLoadingStatus();
                useSystemStore().byPassNavigationGuard();
                router.push('/vehicles')
            }

        })
        .catch((error) => {
            if (error.response.data.message) {
                errors.value.add_vehicle.status = true;
                errors.value.add_vehicle.message = error.response.data.message;
            }

            useSystemStore().disableLoadingStatus();
        })
    } else {
        setTimeout(()=> {
            useSystemStore().disableLoadingStatus();
        }, 400);

    }

}

function sortImages() {
    console.log(fileDropBoxStore.uploadedFiles);
    if (fileDropBoxStore.uploadedFiles.length > 0) {
        const imageIdNew = ref(1);
        fileDropBoxStore.uploadedFiles.forEach((image) => {
            image.id = imageIdNew.value;
            imageIdNew.value++;
        })
    }
}

function validateFields() {
    sortImages();
    resetValidation();
    const overall_status = ref(true);
    if (vehicle.value.stock_number === "") {
        errors.value.stock_number.status = true;
        overall_status.value = false;
    }
    if (vehicle.value.in_stock_date === "") {
        errors.value.instock_date.status = true;
        overall_status.value = false;
    }
    if (vehicle.value.year === "") {
        errors.value.vehicle.year.status = true;
        overall_status.value = false;
    }
    if (vehicle.value.vin === "") {
        errors.value.vehicle.vin.status = true;
        overall_status.value = false;
    }
    if (vehicle.value.make === "") {
        errors.value.vehicle.make.status = true;
        overall_status.value = false;
    }
    if (vehicle.value.model === "") {
        errors.value.vehicle.model.status = true;
        overall_status.value = false;
    }
    if (vehicle.value.specifications.trim === "") {
        errors.value.vehicle.trim.status = true;
        overall_status.value = false;
    }
    if (overall_status.value) {
        return true;
    } else {
        return false;
    }
}

function resetValidation() {
    errors.value = {
        add_vehicle: {
            status : false,
            message : ""
        },
        decoder: {
            status: false,
            message: "VIN Decoder: Error occured."
        },
        stock_number: {
            status: false,
            message: "Stock number is required"
        },
        instock_date: {
            status: false,
            message: "Instock date is required"
        },
        vehicle: {
            vin: {
                status: false,
                message: ""
            },
            year: {
                status: false,
                message: "Required"
            },
            make: {
                status: false,
                message: "Make is required"
            },
            model: {
                status: false,
                message: "Vehicle Model is required"
            },
            trim: {
                status: false,
                message: "Trim is required"
            },
            transmission: {
                status: false,
                message: ""
            },
            style: {
                status: false,
                message: ""
            },
            exterior_color: {
                status: false,
                message: "Exterior colour is required"
            },
            interior_color: {
                status: false,
                message: "Interior colour is required"
            },
            odometer: {
                status: false,
                message: "Odometer reading is required"
            },
            cylinders: {
                status: false,
                message: ""
            },
            engine_size: {
                status: false,
                message: ""
            }
        }
    }
}

function addTransaction(new_transaction) {
    transactions.value.push(new_transaction);
}

function removeTransaction(index) {
    transactions.value.splice(index, 1);
}
</script>
<template>
    <h3 class="text-3xl font-bold mx-2">Edit Vehicle</h3>
    <div v-if="errors.add_vehicle.status" class="grid border border-rounded border-red-500 rounded bg-red-100 mt-4 p-4">
        <h3 class="text-black font-bold">An error occured</h3>
        <h3>{{errors.add_vehicle.message}}</h3>
    </div>
    <div class="grid gap-2 mt-4">
        <div class="border rounded-t-md">
            <div class="bg-gr-grid-top text-[rgb(75 85 99 /1)] uppercase text-sm font-medium rounded-t-md px-4 py-4">Inventory Information</div>
            <div class="bg-white grid grid-cols-3 md:grid-cols-8 gap-4 px-4 py-4 relative">
                <div class="grid grid-cols-1 gap-1 content-start">
                        <label class="text-sm">Stock Number <span class="text-red-400 text-lg leading-3">*</span></label>
                        <input type="text" required class="border rounded" v-model="vehicle.stock_number" :class="{ 'border-red-500' : errors.stock_number.status}">
                        <span v-if="errors.stock_number.status" class="text-xs text-red-500">{{ errors.stock_number.message }}</span>
                </div>
                <div class="grid grid-cols-1 gap-1 content-start">
                        <label class="text-sm">Instock Date <span class="text-red-400 text-lg leading-3">*</span></label>
                        <input type="date" required class="border rounded" v-model="vehicle.in_stock_date" :class="{ 'border-red-500' : errors.instock_date.status}">
                        <span v-if="errors.instock_date.status" class="text-xs text-red-500">{{ errors.instock_date.message }}</span>
                </div>
                <div class="grid grid-cols-1 gap-1 content-start">
                        <label class="text-sm">Status <span class="text-red-400 text-lg leading-3">*</span></label>
                        <select class="border rounded" v-model="vehicle.status" required>
                            <option value="in_stock">In-Stock</option>
                            <option value="pending">Pending Deal</option>
                            <option value="sold">Sold</option>
                            <option value="delivered">Delivered</option>
                            <option value="removed">Removed</option>
                        </select>
                </div>
                <div class="col-span-5 grid justify-stretch sm:justify-items-end sm:justify-items-end items-center">
                    <button class="bg-gr-green text-white text-lg sm:text-md py-2 px-4 rounded-md" v-on:click="submitVehicle()">Update Vehicle</button>
                </div>

            </div>
        </div>
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-6 gap-2 mt-4">
                <!-- Second Section -->
        <div class="border rounded-t-md col-span-3">
            <div class="bg-gr-grid-top rounded-t-md px-4 py-4 flex justify-between">
                <div class="text-[rgb(75 85 99 /1)] uppercase text-sm font-medium">Vehicle Information</div>
                <div v-if="errors.decoder.status" class="border border-red-300 text-red-600 bg-red-100 rounded text-sm px-2 ">{{ errors.decoder.message }}</div>
            </div>
            <div class="bg-white grid grid-cols-1 md:grid-cols-10 lg:grid-cols-10 2xl:grid-cols-12 gap-2 px-4 py-2">
                    <div class="col-span-5 md:col-span-10 lg:col-span-10 2xl:col-span-5 grid grid-cols-1 gap-1 content-start">
                        <label class="text-sm">V.I.N</label>
                        <div class="grid relative content-start">
                            <input type="text" class="border px-2 rounded text-sm" v-model="vehicle.vin"  :class="{ 'border-red-500' : errors.vehicle.vin.status}" />
                            <button class="absolute text-white py-1 px-2 rounded right-0 active:bg-[#00231f] disabled:bg-gray-200 cursor-pointer disabled:cursor-not-allowed bg-gr-green" :disabled="scanVinButton" v-on:click="processVinDecode()">Decode VIN</button>
                        </div>
                    </div>
                    <div class="grid grid-cols-1 gap-1 md:col-span-2 lg:col-span-2 2xl:col-span-1 content-start">
                        <label class="text-sm">Year</label>
                        <input class="border px-2 rounded" v-model="vehicle.year" :class="{ 'border-red-500' : errors.vehicle.year.status}" />
                        <span v-if="errors.vehicle.year.status" class="text-xs text-red-500">{{ errors.vehicle.year.message }}</span>

                    </div>
                    <div class="relative col-span-3 grid md:col-span-4 2xl:col-span-3 grid-cols-1 gap-1 content-start">
                        <label class="text-sm">Make</label>
                        <AutoComplete class="h-8 " v-model="vehicle.make" :suggestions="filteredMakes" dropdown @complete="search" />
                        <span v-if="errors.vehicle.make.status" class="text-xs text-red-500">{{ errors.vehicle.make.message }}</span>
                    </div>
                    <div class="col-span-5 sm:col-span-3  md:col-span-4 2xl:col-span-3 grid grid-cols-1 gap-1 content-start">
                        <label class="text-sm">Model</label>
                        <input class="border px-2 rounded" v-model="vehicle.model" :class="{ 'border-red-500' : errors.vehicle.model.status}" />
                        <span v-if="errors.vehicle.model.status" class="text-xs text-red-500">{{ errors.vehicle.model.message }}</span>
                    </div>
            </div>
            <div class="bg-white grid grid-cols-1 md:grid-cols-9 2xl:grid-cols-12 gap-2 px-4 py-2">
                    <div class="col-span-6 sm:col-span-3 grid grid-cols-1 gap-1 content-start">
                        <label class="text-sm">Trim</label>
                        <input class="border px-2 rounded" v-model="vehicle.specifications.trim"  :class="{ 'border-red-500' : errors.vehicle.trim.status}" />
                        <span v-if="errors.vehicle.trim.status" class="text-xs text-red-500">{{ errors.vehicle.trim.message }}</span>
                    </div>
                    <div class="col-span-6 grid grid-cols-1 gap-1 content-start">
                        <label class="text-sm">Style <span class="text-xs">(Used as a title for website & listing sites)</span></label>
                        <input class="border px-2 rounded" v-model="vehicle.specifications.style" />
                    </div>
            </div>
            <div class="bg-white grid grid-cols-6 md:grid-cols-6 2xl:grid-cols-12 gap-2 px-4 py-2">
                <div class="col-span-3 md:col-span-3 2xl:col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-sm">Engine Capacity</label>
                    <input type="text" class="border px-2 rounded" :value="vehicle.specifications.engine.size " />
                </div>
                <div class="col-span-3 md:col-span-1 grid grid-cols-1 gap-1">
                    <label class="text-sm">Cylinders</label>
                    <select class="border px-2 rounded" v-model="vehicle.specifications.engine.cylinder" >
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="8">8</option>
                        <option value="10">10</option>
                        <option value="12">12</option>
                    </select>
                </div>
                <div class="grid col-span-3 sm:col-span-2 grid-cols-1 gap-1">
                    <label class="text-sm">Transmission</label>
                    <div >
                        <div class="flex">
                            <input id="transmission-auto" type="radio" class="border w-3 rounded" value="automatic" v-model="vehicle.specifications.transmission.type" /> 
                            <label for="transmission-auto" class="text-sm sm:text-xs mx-1">Automatic</label>
                        </div>
                        <div class="flex">
                            <input id="transmission-manual" type="radio" class="border w-3 rounded" value="manual" v-model="vehicle.specifications.transmission.type" /> 
                            <label for="transmission-manual" class="text-sm sm:text-xs mx-1">Manual</label>
                        </div>

                    </div>
                </div>
                <div class="grid col-span-3 sm:col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-sm">Body Style</label>
                    <select class="border px-2 rounded" placeholder="None" v-model="vehicle.specifications.body_type" >
                        <option value="None">Coupe</option>
                        <option value="Hatchback">Hatchback</option>
                        <option value="Sedan">Sedan</option>
                        <option value="SUV">SUV</option>
                        <option value="Truck">Truck</option>
                        <option value="Minivan">Minivan</option>
                        <option value="Cargovan">Cargovan</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                <div class="grid col-span-3 sm:col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-sm">Exterior Color</label>
                    <select class="border px-2 rounded" v-model="vehicle.vehicle_data.exterior_color" placeholder="Select">
                        <option :value="null">Select</option>
                        <option v-for="color in vehicle.specifications.colors.exterior" :value="color">{{ color['Color'] }}</option>
                    </select>
                </div>
                <div class="grid col-span-3 sm:col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-sm">Interior Color</label>
                    <select class="border px-2 rounded" v-model="vehicle.vehicle_data.interior_color" >
                        <option :value="null">Select</option>
                        <option v-for="color in vehicle.specifications.colors.interior" :value="color">{{ color['Color'] }}</option>
                    </select>
                </div>
            </div>
            <div class="bg-white grid grid-cols-6 md:grid-cols-12 gap-2 px-4 py-2">
                    <div class="col-span-3 md:col-span-2 grid grid-cols-1 gap-1">
                        <label class="text-sm">Odometer/Mileage</label>
                        <input type="number" class="border px-2 rounded" v-model="vehicle.odometer" />
                    </div>
                    <div class="grid col-span-3 sm:grid-cols-1 gap-1">
                        <label class="text-sm sm:text-sm">Odometer</label>
                        <div class="flex flex-row sm:flex-col gap-3 sm:gap-1">
                            <div class="flex">
                                <input type="radio" id="radio_odometer_unit_km" class="border w-4 sm:w-3 rounded" value="KM" v-model="vehicle.vehicle_data.odometer_unit" /> 
                                <label for="radio_odometer_unit_km" class="text-sm sm:text-xs mx-1">KM</label>
                            </div>
                            <div class="flex">
                                <input type="radio" id="radio_odometer_unit_miles" class="border w-4 sm:w-3 rounded" value="Miles" v-model="vehicle.vehicle_data.odometer_unit" /> 
                                <label for="radio_odometer_unit_miles" class="text-sm sm:text-xs mx-1">Miles</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-3 sm:col-span-2 grid grid-cols-1 gap-1">
                        <label class="text-sm">MTO Brand</label>
                        <select class="border px-2 rounded" placeholder="None"  v-model="vehicle.vehicle_data.mto_brand">
                            <option value="None">None</option>
                            <option value="Unfit">Unfit</option>
                            <option value="Salvage">Salvage</option>
                        </select>
                    </div>
                    <div class="hidden col-span-2 grid grid-cols-1 gap-1">
                    </div>
                    <div class="col-span-3 sm:col-span-2 grid grid-cols-1 gap-1">
                        <label class="text-sm">Daily Rental?</label>
                        <select class="border px-2 rounded" placeholder="None" v-model="vehicle.vehicle_data.daily_rental">
                            <option :value="true">Yes</option>
                            <option :value="false">No</option>
                        </select>
                    </div>
                    <div class="col-span-2 grid grid-cols-1 gap-1">
                        <label class="text-sm">Certified?</label>
                        <select class="border px-2 rounded" placeholder="Select" v-model="vehicle.vehicle_data.certified">
                            <option :value="true">Yes</option>
                            <option :value="false">No</option>
                        </select>
                    </div>
            </div>
            <div class="bg-white grid grid-cols-12 gap-2 px-4 py-2">
                <div class="col-span-12 sm:col-span-8 grid grid-cols-1 gap-1">
                    <label class="text-sm">CarFax Report Link</label>
                    <input type="url" class="border px-2 rounded" v-model="vehicle.vehicle_data.carfax_report_link" />
                </div>
            </div>
            <div class="bg-white grid grid-cols-12 gap-2 px-4 py-2">
                <div class="col-span-6 sm:col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-sm">Regular Price</label>
                    <input type="number" class="border px-2 rounded" v-model="vehicle.vehicle_data.regular_price" />
                </div>
                <div class="col-span-6 sm:col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-sm">Discounted Price</label>
                    <input type="number" class="border px-2 rounded" v-model="vehicle.vehicle_data.sale_price" />
                </div>
                <div class="col-span-12 sm:col-span-5 grid grid-cols-1 gap-1">
                    <label class="text-sm">Publishing</label>
                    <div class="flex flex-row">
                        <div class="flex items-center">
                            <input type="checkbox" id="publish-on-website" class="border w-3 rounded"  /> 
                            <label for="publish-on-website" class="text-xs mx-2">Website</label>
                        </div>
                        <div class="flex items-center">
                            <input type="checkbox" id="publish-on-listing-sites" class="border w-3 rounded" /> 
                            <label for="publish-on-listing-sites" class="text-xs mx-2">CarGurus/Auto Trader/Kijiji Autos</label>
                        </div>
                    </div>

                </div>
            </div>
            <div class="hidden bg-white flex justify-end gap-2 px-4 py-4">
                <button to="/deals/create" class="bg-gr-green px-4 py-2 rounded-md text-white text-sm">Save</button>
            </div>

        </div>
        <!-- First Section -->
        <div class="border rounded-t-md  col-span-3">
            <div class="bg-gr-grid-top flex justify-between items-center text-[rgb(75 85 99 /1)] uppercase text-sm font-medium rounded-t-md px-4 py-3">
                <div>Vehicle Description</div>
                <div>
                    <button class="bg-gr-green text-white py-1 text-sm px-2 disabled:bg-gray-200 rounded cursor-pointer  active:bg-[#00231f]" :disabled="generateAIButton" v-on:click="generateDescription()"> Generate with AI</button>
                </div>
            </div>
            <!-- <Editor v-model="editor" editorStyle="height: 315px" @load="onEditorLoad" /> -->
            <Editor v-model="editor" editorStyle="height: 315px" @load="onEditorLoad" @text-change="handleTextChange" :key="editorKey" />
        </div>


    </div>

    <!-- Second Container -->
    <div class="grid grid-cols-1 gap-2 mt-4 ">
        <div class="border rounded-t-md">
            <div class="bg-gr-grid-top text-[rgb(75 85 99 /1)] uppercase text-sm font-medium rounded-t-md px-4 py-4">Vehicle Media</div>
            <div class="bg-white grid grid-cols-1 gap-2 px-4 py-2">
                <FileDropBox />
            </div>
            <div class="bg-white grid grid-cols-10 gap-2 px-4 py-2">

            </div>
        </div>
        <!-- <div class="hidden border rounded-t-md">
            <div class="bg-gr-grid-top text-[rgb(75 85 99 /1)] uppercase text-sm font-medium rounded-t-md px-4 py-4">Insurance Information & Extended Warranty</div>
            <div class="bg-white grid grid-cols-9 gap-2 px-4 py-2">
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Policy #</label>
                    <input type="text" class="border px-2 rounded" v-model="customer.driver_license" />
                </div>
                <div class="col-span-3 grid grid-cols-1 gap-1">
                    <label class="text-xs">Broker/Company Name</label>
                    <input class="border px-2 rounded" v-model="customer.driver_license_expiry" />
                </div>
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Phone Number</label>
                    <input type="phone" class="border px-2 rounded" v-model="customer.date_of_birth" />
                </div>
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Expiry Date</label>
                    <input type="date" class="border px-2 rounded" v-model="customer.date_of_birth" />
                </div>
            </div>
            <div class="bg-white grid grid-cols-12 gap-2 px-4 py-2">
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Extended Warranty?</label>
                    <select class="border px-2 rounded" placeholder="None" >
                        <option value="None">No</option>
                        <option value="None">Yes</option>
                    </select>
                </div>

                <div class="col-span-3 grid grid-cols-1 gap-1">
                    <label class="text-xs">Provider</label>
                    <input type="text" class="border px-2 rounded" v-model="customer.driver_license" />
                </div>
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Term (months)</label>
                    <select class="border px-2 rounded" placeholder="None" >
                        <option value="None">3</option>
                        <option value="None">6</option>
                        <option value="None">12</option>
                        <option value="None">18</option>
                        <option value="None">24</option>
                        <option value="None">30</option>
                        <option value="None">36</option>
                        <option value="None">42</option>
                        <option value="None">48</option>
                    </select>
                </div>
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Mileage</label>
                    <input class="border px-2 rounded" v-model="customer.date_of_birth" />
                </div>
                <div class="col-span-3 grid grid-cols-1 gap-1">
                    <label class="text-xs">Warranty Cost <i class="text-2xs">(before tax)</i></label>
                    <input class="border px-2 rounded" v-model="customer.date_of_birth" />
                </div>

            </div>
            <div class="bg-white grid grid-cols-12 gap-2 px-4 py-2">
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Gap Insurance?</label>
                    <select class="border px-2 rounded" placeholder="None" >
                        <option value="None">No</option>
                        <option value="None">Yes</option>
                    </select>
                </div>

                <div class="col-span-3 grid grid-cols-1 gap-1">
                    <label class="text-xs">Provider</label>
                    <input type="text" class="border px-2 rounded" v-model="customer.driver_license" />
                </div>
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Term (months)</label>
                    <select class="border px-2 rounded" placeholder="None" >
                        <option value="None">3</option>
                        <option value="None">6</option>
                        <option value="None">12</option>
                        <option value="None">18</option>
                        <option value="None">24</option>
                        <option value="None">30</option>
                        <option value="None">36</option>
                        <option value="None">42</option>
                        <option value="None">48</option>
                    </select>
                </div>
                <div class="col-span-3 grid grid-cols-1 gap-1">
                    <label class="text-xs">Warranty Cost <i class="text-2xs">(before tax)</i></label>
                    <input class="border px-2 rounded" v-model="customer.date_of_birth" />
                </div>

            </div>
        </div> -->
    </div>

    <!-- Third Container -->

    <div class="grid sm:grid-cols-10 gap-2 mt-4">
        <div class="border rounded-t-md col-span-6 sm:col-span-4" >
            <div class="bg-gr-grid-top text-[rgb(75 85 99 /1)] uppercase text-sm font-medium rounded-t-md px-4 py-4">Features & Options</div>
            <div class="bg-white grid grid-cols-1 gap-y-2 px-4 py-2">
                <p class="col-span-12 text-sm"><span class="font-semibold text-red-500">Please Note:</span> It is the dealer responsibility to review and confirm the following decoded data</p>

                <div class="grid grid-cols-1 gap-6">
                    <div class="grid grid-cols-1 items-center">
                        <h2 class="font-semibold">Mechanical & Powertrain</h2>
                        <VueDraggableNext v-model="vehicle.features.mechanical_and_powertrain">
                            <div v-for="(item, index) in vehicle.features.mechanical_and_powertrain" class="text-sm border-b py-1 cursor-move active:font-bold" >
                            <i class="fa fa-close mr-2 bg-red-500 text-white p-0.5 cursor-pointer" v-on:click="removeFeature('mechanical_and_powertrain', index)"></i>{{ item }}
                        </div>
                        </VueDraggableNext>
                        <div class="text-sm border-b py-2" >
                            <i class="fa fa-plus mr-2 bg-green-500 text-white p-0.5"></i> <input type="text" placeholder="Add a new feature..." v-on:keyup.enter="addFeature('mechanical_and_powertrain',$event)" class="text-sm py-0 w-[80%]" />
                        </div>
                    </div>
                    <div class="grid grid-cols-1 items-center">
                        <h2 class="font-semibold">Safety Features</h2>
                        <div v-for="(item, index) in vehicle.features.safety" class="text-sm border-b py-1" >
                            <i class="fa fa-close mr-2 bg-red-500 text-white p-0.5 cursor-pointer"></i>{{ item }}
                        </div>
                        <div class="text-sm border-b py-1" >
                            <i class="fa fa-plus mr-2 bg-green-500 text-white p-0.5"></i> <input type="text" placeholder="Add a new feature..." v-on:keyup.enter="addFeature('safety',$event)" class="text-sm py-0" />
                        </div>
                    </div>
                    <div class="grid grid-cols-1 items-center">
                        <h2 class="font-semibold">Interior Features</h2>
                        <div v-for="(item, index) in vehicle.features.interior" class="text-sm border-b py-1" >
                            <i class="fa fa-close mr-2 bg-red-500 text-white p-0.5 cursor-pointer"></i>{{ item }}
                        </div>
                        <div class="text-sm border-b py-1" >
                            <i class="fa fa-plus mr-2 bg-green-500 text-white p-0.5"></i> <input type="text" placeholder="Add a new feature..." v-on:keyup.enter="addFeature('interior',$event)" class="text-sm py-0" />
                        </div>
                    </div>
                    <div class="grid grid-cols-1 items-center">
                        <h2 class="font-semibold">Exterior Features</h2>
                        <div v-for="(item, index) in vehicle.features.exterior" class="text-sm border-b py-1" >
                            <i class="fa fa-close mr-2 bg-red-500 text-white p-0.5 cursor-pointer"></i>{{ item }}
                        </div>
                        <div class="text-sm border-b py-1" >
                            <i class="fa fa-plus mr-2 bg-green-500 text-white p-0.5"></i> <input type="text" placeholder="Add a new feature..." v-on:keyup.enter="addFeature('exterior',$event)" class="text-sm py-0" />
                        </div>
                    </div>
                </div>
                <div class="hidden col-span-6 grid grid-cols-1 gap-1 content-center">
                    <div class="grid grid-cols-12 items-center">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1">Vehicle Price</p>
                        <p class="col-span-6 text-normal text-left mr-1 font-semibold">${{ deal.vehicle_price }}</p>
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1">Admin Fee</p>
                        <p class="col-span-6 text-normal text-left mr-1 font-semibold">${{ deal.admin_fee }}</p>
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1">Addon 1</p>
                        <p class="col-span-6 text-normal text-left mr-1 font-semibold">${{ deal.addon_1 }}</p>
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1">Addon 2</p>
                        <p class="col-span-6 text-normal text-left mr-1 font-semibold">${{ deal.addon_2 }}</p>
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1">Addon 3</p>
                        <p class="col-span-6 text-normal text-left mr-1 font-semibold">${{ deal.addon_3 }}</p>
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1">Addon 4</p>
                        <p class="col-span-6 text-normal text-left mr-1 font-semibold">${{ deal.addon_4 }}</p>
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1">Addon 5</p>
                        <p class="col-span-6 text-normal text-left mr-1 font-semibold">${{ deal.addon_5 }}</p>
                    </div>
                    <div class="grid grid-cols-12 items-center border-t">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1 font-bold">Subtotal </p>
                        <p class="col-span-6 text-normal text-left mr-1 font-bold">${{ subtotal }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="border rounded-t-md  col-span-6">
        <div class="bg-gr-grid-top text-[rgb(75 85 99 /1)] uppercase text-sm font-medium rounded-t-md px-4 py-4">Purchase & Cost Information</div>
            <div class="bg-white py-2">
                <h3 class="px-4">Seller Information</h3>
                <p class="px-4 text-sm">This information will be used for the Garage Register</p>
                <div class="bg-white grid grid-cols-12 gap-2 px-4 py-2">
                    <div class="col-span-3 grid grid-cols-1 gap-1">
                        <label class="text-sm">Name</label>
                        <input type="text" class="border px-2 rounded" v-model="vehicle.vehicle_data.garage_register.name" />
                    </div>
                    <div class="col-span-5 grid grid-cols-1 gap-1">
                        <label class="text-sm">Full Address</label>
                        <input type="text" class="border px-2 rounded" v-model="vehicle.vehicle_data.garage_register.address" />
                    </div>
                    <div class="col-span-2 grid grid-cols-1 gap-1">
                        <label class="text-sm">Plate No.</label>
                        <input type="text" class="border px-2 rounded" v-model="vehicle.vehicle_data.garage_register.plate_no" />
                    </div>
                    <div class="col-span-2 grid grid-cols-1 gap-1">
                        <label class="text-sm">Purpose</label>
                        <select class="border px-2 rounded" v-model="vehicle.vehicle_data.garage_register.purpose" >
                            <option value="resale">Re-sale</option>
                            <option value="wrecking">Wrecking</option>
                            <option value="consignement">Consignement</option>
                        </select>
                    </div>
                </div>
        </div>
            <TransactionTable class="hidden" :transactions="transactions" @add-transaction="addTransaction" @remove-transaction="removeTransaction"/>
        </div>
    </div>
    <Teleport to="body">
        <Transition name="fade" v-if="useSystemStore().closeModalStatus">
            <div class="w-screen h-screen bg-black bg-opacity-60 fixed left-0 top-0 flex justify-center items-center">
                <div class="bg-white rounded py-8 px-6 flex flex-col gap-2 mx-2">
                    <h4 class="text-[24px] font-semibold"><i class="text-red-700 fa fa-triangle-exclamation"></i> You did not save your work</h4>
                    <p>You did not save your changes. Are you sure you want to navigate away?</p>
                    <div class="flex mt-4 gap-4">
                        <button class="bg-gr-green rounded text-white text-lg py-1.5 px-6" v-on:click="useSystemStore().cancelNavigation()">Stay</button>
                        <button class="bg-red-500 rounded text-white text-lg py-1.5 px-6"  v-on:click="useSystemStore().continueNavigation()">Close & Disgard Changes</button>
                    </div>
                </div>
            </div>
        </Transition>
    </Teleport>
</template>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>