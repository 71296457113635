import { createWebHistory, createRouter } from "vue-router";
import Dashboard from '../pages/Dashboard.vue';
import DealsView from '../pages/DealsView.vue'
import CreateDealView from '../pages/CreateDealView.vue'
import PrintBillOfSale from '../pages/PrintBillOfSale.vue'
import CreateVehicleView from "../pages/CreateVehicleView.vue";
import Vehicles from "../pages/Vehicles.vue";
import Login from "../pages/Login.vue";
import VINScanner from "../pages/VINScanner.vue";
import Leads from "../pages/Leads/Leads.vue"
import Reports from "../pages/Reports/Reports.vue"
import Customers from "../pages/Customers/Customers.vue"
import EditVehicle from "../pages/Vehicles/EditVehicle.vue"
import DealsPrintBillOfSale from "../pages/Deals/PrintBillOfSale.vue"
import {useSystemStore} from "../stores/system"
const routes = [
    { path: '/dashboard', component: Dashboard},
    { path: '/deals', component: DealsView},
    { path: '/deals/print', component: DealsView},
    { path: '/deals/create', component: CreateDealView},
    { path: '/deals/print1', component: DealsPrintBillOfSale},
    { path: '/vehicles', component: Vehicles},
    { path: '/vehicles/create', component: CreateVehicleView},
    { path: '/login', component: Login},
    { path: '/deals/:id/view', component: Login},
    { path: '/vehicles/:id/edit', component: EditVehicle, props: true},
    { path: '/scanner', component: VINScanner},
    { path: '/leads', component: Leads},
    { path: '/reports', component: Reports},
    { path: '/customers', component: Customers},
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from) => {
    if (from.path === '/vehicles/create' && useSystemStore().closeModalUserStatus != true) {
        useSystemStore().openCloseModal(to);
        return false;
    }
})
  

export default(
    router
)