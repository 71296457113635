<script setup>
import { defineEmits } from 'vue';
import { useRoute  } from 'vue-router';
const closeMobileMenuEmit = defineEmits(['closeMobileMenu']);

const closeMobileMenu = () => {
    closeMobileMenuEmit('closeMobileMenu')
}
</script>
<template>
    <div class="flex flex-col sm:flex-row md:flex col-span-8 sm:justify-center sm:items-center gap-8">
        <RouterLink to="/dashboard" v-on:click="closeMobileMenu()" :class="{'bg-[#005e54] text-white' : useRoute().matched.some(({ path }) => path.startsWith('/dashboard'))}" class="sm:text-white hover:bg-[#005e54] py-2 px-4 rounded"><i class="fa fa-dashboard mr-2"></i>Dashboard</RouterLink>
        <RouterLink to="/deals" v-on:click="closeMobileMenu()" :class="{'bg-[#005e54] text-white' : useRoute().matched.some(({ path }) => path.startsWith('/deals'))}" class="sm:text-white hover:bg-[#005e54] py-2 px-4 rounded"><i class="fa fa-dollar mr-2"></i>Deals</RouterLink>
        <RouterLink to="/vehicles" v-on:click="closeMobileMenu()" :class="{'bg-[#005e54] text-white' : useRoute().matched.some(({ path }) => path.startsWith('/vehicles'))}" class="sm:text-white hover:bg-[#005e54] py-2 px-4 rounded"><i class="fa fa-car mr-2"></i>Inventory</RouterLink>
        <RouterLink to="/customers" v-on:click="closeMobileMenu()" :class="{'bg-[#005e54] text-white' : useRoute().matched.some(({ path }) => path.startsWith('/customers'))}" class="sm:text-white hover:bg-[#005e54] py-2 px-4 rounded hidden"><i class="fa fa-users mr-2"></i>Customers</RouterLink>
        <RouterLink to="/leads" v-on:click="closeMobileMenu()" :class="{'bg-[#005e54] text-white' : useRoute().matched.some(({ path }) => path.startsWith('/leads'))}" class="sm:text-white hover:bg-[#005e54] py-2 px-4 rounded hidden"><i class="fa fa-at mr-2"></i>Leads</RouterLink>
        <RouterLink to="/reports" v-on:click="closeMobileMenu()" :class="{'bg-[#005e54] text-white' : useRoute().matched.some(({ path }) => path.startsWith('/reports'))}" class="sm:text-white hover:bg-[#005e54] py-2 px-4 rounded hidden"><i class="fa fa-chart-simple mr-2"></i>Reports</RouterLink>
    </div>

</template>