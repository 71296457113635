import { defineStore } from "pinia";
import { ref } from "vue";
import axios from "axios";
import { useAuthStore } from "./auth";
import { useDealStore } from "./deals"
import { useSystemStore } from "./system"

export const useDealerStore = defineStore('dealerStore', () => {

    const dealStore = useDealStore();
    const dealerId = ref(null);
    const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;
    const dealerDataStatus = ref(false);
    const userData = ref([]);
    const lastFetched = ref();
    const dealerData = ref({
        dealerId: '10001',
        dealerNumber: '',
        dealer_name: 'Loading...',
        dealerAddress: '123 Street Name',
        dealerCity: 'Mississauga',
        dealerState: 'ON',
        dealerZip: 'L7M 4R6',
        dealerCountry: 'CAD',
        dealerStatus: 'active',
        id: null,
        dealer_settings: {
            plan: {
                features: {
                    deals : false
                }
            }
        }
    });
    const dealerLimits = ref({
        activeVehiclesLimit: 15,
        currentVehicleUsage: 10,
        vinDecoderLimit: 25,
        currentVinDecoderUsage: 10,
        activeDealsLimit: 15,
        currentDealsUsage: 5
    });


    const getUserData = () => {
        return userData.value;
    }

    const getDealerData = () => {
        //fetchDealerData(dealerId.value);
        return dealerData.value;
    }

    const getDealerLimits = () => {
        return dealerLimits.value;
    }

    const getDealerId = () => {
        if (dealerData.value.id != null) {
            return dealerData.value.id;
        }
    }

    const fetchAllowed = () => {
        if (!lastFetched.value) {
            return true;
        }

        const currentTime = new Date();
        const timeDifference = (currentTime - lastFetched.value) / 1000 / 60; // Difference in minutes
        if (timeDifference > 5) {
            console.log("More than 5 minutes have passed.");
            return true;
          } else {
            console.log("Less than 5 minutes have passed.");
            return false;
          }        
    }
    const fetchDealerData = (dealerId) => {

        if (fetchAllowed()) {
            useSystemStore().enableLoadingStatus();
            const authStore = useAuthStore();
            const configs = {
                headers: {
                    "x-auth-token" : authStore.getAuthToken()
                }
            }
            axios.get( apiEndpoint + '/dealer/' + dealerId, configs)
            .then((response) => {
                if (response.status === 200) {
                    dealerData.value = response.data.data;
                    //dealerLimits.value = response.data.dealer_limits;
                    dealerDataStatus.value = true;
                    dealStore.fetchDealsSummary();
                }
                lastFetched.value = new Date();
                useSystemStore().disableLoadingStatus();
            })
            .catch((error) => {
    
            })
    
        }
    }

    return {
        getDealerData,
        getDealerLimits,
        getUserData,
        fetchDealerData,
        dealerDataStatus,
        dealerData,
        getDealerId
    }
})