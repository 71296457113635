import { defineStore } from "pinia";
import { ref } from "vue";
import axios from "axios";
import { useAuthStore } from "./auth";
import { getVehiclesApi } from "../services/vehicle_apis";

export const useVehiclesStore = defineStore('vehiclesStore', () => {

    const vehicles = ref([]);
    const activeVehicles = ref([]);
    const vehiclesCount = ref([]);
    const filteredVehicles = ref([]);
    const authStore = useAuthStore();
    const vehicleModal = ref([])
    const vehicleModalStatus = ref(false)

    const getVehicles = () => {
        getVehiclesApi();
    }

    const filterVehicles = () => {
        return authToken.value;
    }

    const searchVehicles = (keyword) => {
        return userData.value;
    }

    const setVehicles = (vehiclesList) => {
        vehicles.value = vehiclesList;
    }

    const resetVehicles = () => {
        vehicles.value = []
    }

    const setVehicleModal = (vehicleId) => {
        vehicleModalStatus.value = true
        
    }

    const resetVehicleModal = () => {
        vehicleModalStatus.value = false
    }
    return {
        vehicles,
        getVehicles,
        setVehicles,
        resetVehicles,
        setVehicleModal,
        resetVehicleModal,
        vehicleModal,
        vehicleModalStatus
    }
})