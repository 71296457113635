<script setup>
    import { ref, onMounted, computed } from "vue";
    import DealAddons from '../components/Deals/DealAddons.vue'

    const deal_addons = ref([]);
    const customer = ref({
        firstname: "Yousif",
        lastname: "Bodagh",
        middlename: "Raad",
        email: "yousifbodagh@gmail.com",
        phone: '647-770-0283',
        driver_license: 'B5405-47425-45552',
        driver_license_expiry: '12/05/2025',
        date_of_birth: '03/05/1995',
        street_address: '2781 Darien Road',
        street_address2: 'Unit 27',
        city: 'Burlington',
        postal_code: 'L7M 4R6',
        province: 'ON'
    })
    const deal = ref({
        deal_number: 1001,
        vehicle_price: 19999.00,
        admin_fee: 499,
        addon_1: 2,
        addon_2: 0,
        addon_3: 0,
        addon_4: 0,
        addon_5: 0,
        extended_warranty: "",
        gap_insurance: "",
        licensing_fee: "",
        gasoline: "",
        trade_in: 1200,
        lien_payout: 7000
    })

    const subtotal = computed(() => {
        return (parseFloat(deal.value.vehicle_price) + parseFloat(deal.value.admin_fee) + parseFloat(deal.value.addon_1) + parseFloat(deal.value.addon_2) + parseFloat(deal.value.addon_3) + parseFloat(deal.value.addon_4) + parseFloat(deal.value.addon_5)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    })
    const vehicle = ref({
        stock_number: "",
        vin: "",
        year: "",
        make: "",
        model: "",
        trim: "",
        odometer: "",
        odometer_unit: "",
        mto_brand: "",
        exterior_color: "",
        daily_rental: "",
        certified: "",
        delivery_date:"",
        delivery_note: ""
    })

    const insurance = ref({
        policy_number: "",
        company: "",
        insurer_phone: "",
        policy_expiry: ""
    })

    const trade_in = ref({
        vin: "",
        year: "",
        make: "",
        model: "",
        trim: "",
        odometer: "",
        odometer_unit: "",
        mto_brand: "",
        exterior_color: "",
        accidents: "",
        disclosures: "",
        trade_in_value: "",
        lien_payout: "",
        lien_holder: ""

    })
    const lastname = ref(null)
    onMounted(() => {
        window.addEventListener('keydown', (e) => {
      if (e.key == 'Escape') {
        customer.value.lastname.focus()
      }
    });
    })
</script>
<template>
    <h3 class="text-3xl font-bold">Create A New Deal</h3>
    <div class="grid gap-2 mt-4">
        <div class="border rounded-t-md">
            <div class="bg-gr-grid-top text-[rgb(75 85 99 /1)] uppercase text-sm font-medium rounded-t-md px-4 py-4">Deal Information</div>
            <div class="bg-white grid grid-cols-12 gap-2 px-4 py-4">
                <div class="grid grid-cols-1 gap-1">
                        <label class="text-xs">Deal Number</label>
                        <h3>{{ deal.deal_number }}</h3>
                </div>

            </div>
        </div>
    </div>

    <div class="grid grid-cols-2 gap-2 mt-4">
        <!-- First Section -->
        <div class="border rounded-t-md">
            <div class="bg-gr-grid-top text-[rgb(75 85 99 /1)] uppercase text-sm font-medium rounded-t-md px-4 py-4">Customer Information</div>
            <div class="bg-white grid grid-cols-3 gap-2 px-4 py-4">
                    <div class="grid grid-cols-1 gap-1">
                        <label class="text-xs">First Name</label>
                        <input  @keyup.alt.67="YourFn" class="border px-2 rounded" v-model="customer.firstname" />
                    </div>
                    <div class="grid grid-cols-1 gap-1">
                        <label class="text-xs">Middle Name</label>
                        <input class="border px-2 rounded" v-model="customer.middlename" />
                    </div>
                    <div class="grid grid-cols-1 gap-1">
                        <label class="text-xs">Last Name</label>
                        <input class="border px-2 rounded" v-model="customer.lastname"  ref="customer.value.lastname" />
                    </div>
            </div>
            <div class="bg-white grid grid-cols-2 gap-2 px-4 py-2">
                    <div class="grid grid-cols-1 gap-1">
                        <label class="text-xs">Email Address</label>
                        <input type="email" class="border px-2 rounded" v-model="customer.email" />
                    </div>
                    <div class="grid grid-cols-1 gap-1">
                        <label class="text-xs">Phone Number</label>
                        <input class="border px-2 rounded" v-model="customer.phone" />
                    </div>
            </div>
            <div class="bg-white grid grid-cols-5 gap-2 px-4 py-2">
                    <div class="col-span-2 grid grid-cols-1 gap-1">
                        <label class="text-xs">Driver License Number</label>
                        <input type="text" class="border px-2 rounded" v-model="customer.driver_license" />
                    </div>
                    <div class="grid grid-cols-1 gap-1">
                        <label class="text-xs">License Expiry</label>
                        <input class="border px-2 rounded" v-model="customer.driver_license_expiry" />
                    </div>
                    <div class="grid grid-cols-1 gap-1">
                        <label class="text-xs">Date of Birth</label>
                        <input class="border px-2 rounded" v-model="customer.date_of_birth" />
                    </div>
                    <div class="grid grid-cols-1 gap-1">
                        <label class="text-xs">Gender</label>
                        <select class="border px-2 rounded" placeholder="Select" >
                            <option disabled>Select</option>
                        </select>
                    </div>
            </div>
            <div class="bg-white grid grid-cols-7 gap-2 px-4 py-2">
                    <div class="col-span-2 grid grid-cols-1 gap-1">
                        <label class="text-xs">Street Address</label>
                        <input type="text" class="border px-2 rounded" v-model="customer.street_address" />
                    </div>
                    <div class="col-span-2 grid grid-cols-1 gap-1">
                        <label class="text-xs">Street Address 2</label>
                        <input type="text" class="border px-2 rounded" v-model="customer.street_address2" />
                    </div>
                    <div class="grid grid-cols-1 gap-1">
                        <label class="text-xs">City</label>
                        <input class="border px-2 rounded" v-model="customer.city" />
                    </div>
                    <div class="grid grid-cols-1 gap-1">
                        <label class="text-xs">Province</label>
                        <select class="border px-2 rounded" placeholder="Select" >
                            <option value="ON">Ontario</option>
                            <option value="AB">Alberta</option>
                            <option value="BC">British Columbia</option>
                            <option value="NS">Nova Scotia</option>
                            <option value="PE">Prince Edward Island</option>
                            <option value="QC">Quebec</option>
                            <option value="SK">Saskatoon</option>
                        </select>                    </div>
                    <div class="grid grid-cols-1 gap-1">
                        <label class="text-xs">Postal Code</label>
                        <input class="border px-2 rounded" v-model="customer.postal_code" />
                    </div>
            </div>
            <div class="bg-white flex justify-end gap-2 px-4 py-4">
                <button to="/deals/create" class="bg-gr-green px-4 py-2 rounded-md text-white text-sm">Save</button>
            </div>
        </div>

        <!-- Second Section -->
        <div class="border rounded-t-md">
            <div class="bg-gr-grid-top text-[rgb(75 85 99 /1)] uppercase text-sm font-medium rounded-t-md px-4 py-4">Vehicle Information</div>
            <div class="bg-white grid grid-cols-3 gap-2 px-4 py-4">
                <div class="col-span-2 grid grid-cols-1 ">
                    <label class="text-xs">Search Inventory</label>
                    <div>
                        <input type="text"  class="border px-2 rounded" />
                        <button class="border ml-2 px-2 py-1 rounded-md border-gray-300 hover:bg-gr-green hover:text-white hover:border-gr-green">Find Vehicle</button>
                    </div>
                </div>
                <div>
                    <label class="text-xs">Stock Number</label>
                    <input type="text" class="border px-2 rounded" v-model="vehicle.stock_number" />
                </div>
            </div>
            <div class="bg-white grid grid-cols-9 gap-2 px-4 py-2">
                    <div class="col-span-3 grid grid-cols-1 gap-1">
                        <label class="text-xs">V.I.N</label>
                        <input type="text" class="border px-2 rounded" v-model="vehicle.vin" />
                    </div>
                    <div class="grid grid-cols-1 gap-1">
                        <label class="text-xs">Year</label>
                        <input class="border px-2 rounded" v-model="vehicle.year" />
                    </div>
                    <div class="col-span-2 grid grid-cols-1 gap-1">
                        <label class="text-xs">Make</label>
                        <select class="border px-2 rounded" placeholder="Select" >
                            <option disabled>Select</option>
                        </select>
                    </div>
                    <div class="col-span-2 grid grid-cols-1 gap-1">
                        <label class="text-xs">Model</label>
                        <input class="border px-2 rounded" v-model="vehicle.model" />
                    </div>
                    <div class="col-span-1 grid grid-cols-1 gap-1">
                        <label class="text-xs">Trim</label>
                        <input class="border px-2 rounded" v-model="vehicle.trim" />
                    </div>
            </div>
            <div class="bg-white grid grid-cols-12 gap-2 px-4 py-2">
                    <div class="col-span-2 grid grid-cols-1 gap-1">
                        <label class="text-xs">Odometer/Mileage</label>
                        <input type="text" class="border px-2 rounded" v-model="vehicle.odometer" />
                    </div>
                    <div class="grid grid-cols-1 gap-1">
                        <label class="text-xs">Odometer</label>
                        <div>
                            <div class="flex">
                                <input type="checkbox" class="border w-3 rounded" v-model="customer.driver_license_expiry" /> 
                                <label class="text-xs mx-1">KM</label>
                            </div>
                            <div class="flex">
                                <input type="checkbox" class="border w-3 rounded" v-model="customer.driver_license_expiry" /> 
                                <label class="text-xs mx-1">Miles</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-2 grid grid-cols-1 gap-1">
                        <label class="text-xs">MTO Brand</label>
                        <select class="border px-2 rounded" placeholder="None" v-model="vehicle.mto_brand" >
                            <option value="None">None</option>
                            <option value="None">Unfit</option>
                            <option value="None">Salvage</option>
                        </select>
                    </div>
                    <div class="col-span-2 grid grid-cols-1 gap-1">
                        <label class="text-xs">Exterior Color</label>
                        <input class="border px-2 rounded" v-model="vehicle.exterior_color" />
                    </div>
                    <div class="col-span-2 grid grid-cols-1 gap-1">
                        <label class="text-xs">Daily Rental?</label>
                        <select class="border px-2 rounded" placeholder="None" v-model="vehicle.daily_rental">
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                    <div class="col-span-2 grid grid-cols-1 gap-1">
                        <label class="text-xs">Certified?</label>
                        <select class="border px-2 rounded" placeholder="None" v-model="vehicle.certified">
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
            </div>
            <div class="bg-white grid grid-cols-12 gap-2 px-4 py-2">
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Delivery Date</label>
                    <input type="date" class="border px-2 rounded" v-model="deal.delivery_date" />
                </div>
                <div class="col-span-5 grid grid-cols-1 gap-1">
                    <label class="text-xs">Delivery Notes</label>
                    <input type="text" class="border px-2 rounded" v-model="deal.delivery_note" />
                </div>
                <div class="col-span-5 grid grid-cols-1 gap-1">
                    <label class="text-xs">Other Disclosures</label>
                    <div>
                        <div class="flex ">
                            <input type="checkbox" class="border w-3 rounded" v-model="deal.disclosures" /> 
                            <label class="text-xs mx-2">Actual distance travelled may be higher</label>
                        </div>
                    </div>

                </div>
            </div>
            <div class="bg-white flex justify-end gap-2 px-4 py-4">
                <button to="/deals/create" class="bg-gr-green px-4 py-2 rounded-md text-white text-sm">Save</button>
            </div>

        </div>
    </div>

    <!-- Second Container -->
    <div class="grid grid-cols-2 gap-2 mt-4">
        <div class="border rounded-t-md">
            <div class="bg-gr-grid-top text-[rgb(75 85 99 /1)] uppercase text-sm font-medium rounded-t-md px-4 py-4">Trade-In Information</div>
            <div class="bg-white grid grid-cols-9 gap-2 px-4 py-2">
                <div class="col-span-3 grid grid-cols-1 gap-1">
                    <label class="text-xs">V.I.N</label>
                    <input type="text" class="border px-2 rounded" v-model="customer.driver_license" />
                </div>
                <div class="grid grid-cols-1 gap-1">
                    <label class="text-xs">Year</label>
                    <input class="border px-2 rounded" v-model="customer.driver_license_expiry" />
                </div>
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Make</label>
                    <select class="border px-2 rounded" placeholder="Select" >
                        <option disabled>Select</option>
                    </select>
                </div>
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Model</label>
                    <input class="border px-2 rounded" v-model="customer.date_of_birth" />
                </div>
                <div class="col-span-1 grid grid-cols-1 gap-1">
                    <label class="text-xs">Trim</label>
                    <input class="border px-2 rounded" v-model="customer.date_of_birth" />
                </div>
            </div>
            <div class="bg-white grid grid-cols-12 gap-2 px-4 py-2">
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Odometer/Mileage</label>
                    <input type="text" class="border px-2 rounded" v-model="customer.driver_license" />
                </div>
                <div class="grid grid-cols-1 gap-1">
                    <label class="text-xs">Unit</label>
                    <div>
                        <div class="flex">
                            <input type="checkbox" class="border w-3 rounded" v-model="customer.driver_license_expiry" /> 
                            <label class="text-xs mx-1">KM</label>
                        </div>
                        <div class="flex">
                            <input type="checkbox" class="border w-3 rounded" v-model="customer.driver_license_expiry" /> 
                            <label class="text-xs mx-1">Miles</label>
                        </div>
                    </div>
                </div>
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Exterior Color</label>
                    <input class="border px-2 rounded" v-model="customer.date_of_birth" />
                </div>
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Accident?</label>
                    <select class="border px-2 rounded" placeholder="None" >
                        <option value="None">No</option>
                        <option value="None">Yes</option>
                        <option value="None">Not sure</option>
                    </select>
                </div>
                <div class="col-span-5 grid grid-cols-1 gap-1">
                    <label class="text-xs">Discosures</label>
                    <input class="border px-2 rounded" v-model="customer.date_of_birth" />
                </div>
            </div>
            <div class="bg-white grid grid-cols-6 gap-2 px-4 py-2">
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Trade-In Value</label>
                    <input class="border px-2 rounded" v-model="customer.date_of_birth" />
                </div>
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Lien Payout</label>
                    <input class="border px-2 rounded" v-model="customer.date_of_birth" />
                </div>
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Lien Holder</label>
                    <input class="border px-2 rounded" v-model="customer.date_of_birth" />
                </div>

            </div>
        </div>
        <div class="border rounded-t-md">
            <div class="bg-gr-grid-top text-[rgb(75 85 99 /1)] uppercase text-sm font-medium rounded-t-md px-4 py-4">Insurance Information & Extended Warranty</div>
            <div class="bg-white grid grid-cols-9 gap-2 px-4 py-2">
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Policy #</label>
                    <input type="text" class="border px-2 rounded" v-model="customer.driver_license" />
                </div>
                <div class="col-span-3 grid grid-cols-1 gap-1">
                    <label class="text-xs">Broker/Company Name</label>
                    <input class="border px-2 rounded" v-model="customer.driver_license_expiry" />
                </div>
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Phone Number</label>
                    <input type="phone" class="border px-2 rounded" v-model="customer.date_of_birth" />
                </div>
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Expiry Date</label>
                    <input type="date" class="border px-2 rounded" v-model="customer.date_of_birth" />
                </div>
            </div>
            <div class="bg-white grid grid-cols-12 gap-2 px-4 py-2">
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Extended Warranty?</label>
                    <select class="border px-2 rounded" placeholder="None" >
                        <option value="None">No</option>
                        <option value="None">Yes</option>
                    </select>
                </div>

                <div class="col-span-3 grid grid-cols-1 gap-1">
                    <label class="text-xs">Provider</label>
                    <input type="text" class="border px-2 rounded" v-model="customer.driver_license" />
                </div>
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Term (months)</label>
                    <select class="border px-2 rounded" placeholder="None" >
                        <option value="None">3</option>
                        <option value="None">6</option>
                        <option value="None">12</option>
                        <option value="None">18</option>
                        <option value="None">24</option>
                        <option value="None">30</option>
                        <option value="None">36</option>
                        <option value="None">42</option>
                        <option value="None">48</option>
                    </select>
                </div>
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Mileage</label>
                    <input class="border px-2 rounded" v-model="customer.date_of_birth" />
                </div>
                <div class="col-span-3 grid grid-cols-1 gap-1">
                    <label class="text-xs">Warranty Cost <i class="text-2xs">(before tax)</i></label>
                    <input class="border px-2 rounded" v-model="customer.date_of_birth" />
                </div>

            </div>
            <div class="bg-white grid grid-cols-12 gap-2 px-4 py-2">
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Gap Insurance?</label>
                    <select class="border px-2 rounded" placeholder="None" >
                        <option value="None">No</option>
                        <option value="None">Yes</option>
                    </select>
                </div>

                <div class="col-span-3 grid grid-cols-1 gap-1">
                    <label class="text-xs">Provider</label>
                    <input type="text" class="border px-2 rounded" v-model="customer.driver_license" />
                </div>
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Term (months)</label>
                    <select class="border px-2 rounded" placeholder="None" >
                        <option value="None">3</option>
                        <option value="None">6</option>
                        <option value="None">12</option>
                        <option value="None">18</option>
                        <option value="None">24</option>
                        <option value="None">30</option>
                        <option value="None">36</option>
                        <option value="None">42</option>
                        <option value="None">48</option>
                    </select>
                </div>
                <div class="col-span-3 grid grid-cols-1 gap-1">
                    <label class="text-xs">Warranty Cost <i class="text-2xs">(before tax)</i></label>
                    <input class="border px-2 rounded" v-model="customer.date_of_birth" />
                </div>

            </div>
        </div>
    </div>

    <!-- Third Container -->

    <div class="grid grid-cols-2 gap-2 mt-4">
        <div class="border rounded-t-md">
            <div class="bg-gr-grid-top text-[rgb(75 85 99 /1)] uppercase text-sm font-medium rounded-t-md px-4 py-4">Pricing Information</div>
            <div class="bg-white grid grid-cols-12 gap-2 px-4 py-2">
                <div class="col-span-6 grid grid-cols-1 gap-1">
                    <div class="grid grid-cols-12 items-center">
                        <label class="col-span-6 text-normal">Vehicle Price</label>
                        <input type="text" class="col-span-4 border px-2 rounded" v-model="deal.vehicle_price" />
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <label class="col-span-6 text-normal">Administration Fee</label>
                        <input type="text" class="col-span-4 border px-2 rounded" v-model="deal.admin_fee" />
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <label class="col-span-6 text-normal">Addon 1</label>
                        <input type="text" class="col-span-4 border px-2 rounded" v-model="deal.addon_1" />
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <label class="col-span-6 text-normal">Addon 2</label>
                        <input type="text" class="col-span-4 border px-2 rounded" v-model="deal.addon_2" />
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <label class="col-span-6 text-normal">Addon 3</label>
                        <input type="text" class="col-span-4 border px-2 rounded" v-model="deal.addon_3" />
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <label class="col-span-6 text-normal">Addon 4</label>
                        <input type="text" class="col-span-4 border px-2 rounded" v-model="deal.addon_4" />
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <label class="col-span-6 text-normal">Addon 5</label>
                        <input type="text" class="col-span-4 border px-2 rounded" v-model="deal.addon_5" />
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <label class="col-span-6 text-normal">Extended Warranty</label>
                        <input type="text" class="col-span-4 border px-2 rounded" v-model="deal.extended_warranty" />
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <label class="col-span-6 text-normal">Gap Insurance</label>
                        <input type="text" class="col-span-4 border px-2 rounded" v-model="deal.gap_insurance" />
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <label class="col-span-6 text-normal">Licensing Fee</label>
                        <input type="text" class="col-span-4 border px-2 rounded" v-model="deal.licensing_fee" />
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <label class="col-span-6 text-normal">Gasoline</label>
                        <input type="text" class="col-span-4 border px-2 rounded" v-model="deal.gasoline" />
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <label class="col-span-6 text-normal">Deposit</label>
                        <input type="text" class="col-span-4 border px-2 rounded" v-model="deal.deposit" />
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <label class="col-span-6 text-normal">Trade-In</label>
                        <input type="text" class="col-span-4 border px-2 rounded" v-model="deal.trade_in" />
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <label class="col-span-6 text-normal">Lien Payout</label>
                        <input type="text" class="col-span-4 border px-2 rounded" v-model="deal.lien_payout" />
                    </div>
                </div>
                <div class="col-span-6 grid grid-cols-1 gap-1 content-center">
                    <div class="grid grid-cols-12 items-center">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1">Vehicle Price</p>
                        <p class="col-span-6 text-normal text-left mr-1 font-semibold">${{ deal.vehicle_price }}</p>
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1">Admin Fee</p>
                        <p class="col-span-6 text-normal text-left mr-1 font-semibold">${{ deal.admin_fee }}</p>
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1">Addon 1</p>
                        <p class="col-span-6 text-normal text-left mr-1 font-semibold">${{ deal.addon_1 }}</p>
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1">Addon 2</p>
                        <p class="col-span-6 text-normal text-left mr-1 font-semibold">${{ deal.addon_2 }}</p>
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1">Addon 3</p>
                        <p class="col-span-6 text-normal text-left mr-1 font-semibold">${{ deal.addon_3 }}</p>
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1">Addon 4</p>
                        <p class="col-span-6 text-normal text-left mr-1 font-semibold">${{ deal.addon_4 }}</p>
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1">Addon 5</p>
                        <p class="col-span-6 text-normal text-left mr-1 font-semibold">${{ deal.addon_5 }}</p>
                    </div>
                    <div class="grid grid-cols-12 items-center border-t">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1 font-bold">Subtotal </p>
                        <p class="col-span-6 text-normal text-left mr-1 font-bold">${{ subtotal }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="border rounded-t-md">
            <div class="bg-gr-grid-top text-[rgb(75 85 99 /1)] uppercase text-sm font-medium rounded-t-md px-4 py-4">Extended Warranty, Insurance, and Addons</div>
            <div class="bg-white grid grid-cols-12 gap-2 px-4 py-2">
                <div class="col-span-12">
                    <DealAddons :addons="deal_addons" />

                </div>
            </div>
        </div>
    </div>

</template>